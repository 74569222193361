export default {
    data() {
        return StyleCre.getVueData(this, {
            showSideMenu: false
        });
    },
    computed: StyleCre.getVueComputed(this,{
        prueba(){
            return new Date();
        }
    }),
    methods: StyleCre.getVueMethods(this, {
        closeSideMenu() {
            this.showSideMenu = false;
        }
    }),
    mounted(){
        StyleCre.executeVuePreMounted(this);
    }
}