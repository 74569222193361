import Vue from 'vue';
import Vuex from 'vuex'

import authorization from "./store/authorization";
import CommonMixin from "./common-app-mixin";

const store = new Vuex.Store({
    modules: {
        authorization,
    },
    state: {
    },
    mutations: {
    },
    actions: {
    },
    getters: {
    }
});

var app = new Vue({
    delimiters: ["${","}"],
    mixins: [CommonMixin],
    store,
    created() {
    }
}).$mount('#app');