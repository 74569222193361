import moment from "moment";
import axios from "axios";
import qs from "query-string";

export default {
    state: {
        authorized: false,
        tokenPromise: false,
        access_token: {
            type: null,
            token: null,
            expires: null
        },
    },
    mutations: {
        setTokenPromise(state, tokenPromise) {
            state.tokenPromise = tokenPromise;
        },
        setAuthorized(state, authorized) {
            state.authorized = authorized;
        },
        setAccessToken(state, access_token) {
            state.access_token = access_token;
        }
    },
    actions: {
        getAccessToken(context) {
            if (!context.state.tokenPromise) {
                context.commit(
                    'setTokenPromise',
                    !context.state.authorized ||
                    context.state.access_token.expires < moment() ?
                        axios
                            .post(
                                ROOTAPI + 'oauth/access_token/',
                                qs.stringify({
                                    grant_type: 'client_credentials',
                                    client_id: 'FLUNA_WEB',
                                    client_secret: 'abc123',
                                    scope: 'reviews'
                                }),
                                {
                                    headers: {
                                        'Content-Type': 'application/x-www-form-urlencoded'
                                    }
                                }
                            )
                            .then(res => {
                                context.commit('setAuthorized', true);

                                const access_token = {
                                    type: res.data.token_type,
                                    token: res.data.access_token,
                                    expires: moment().add(res.data.expires_in, 's')
                                };
                                context.commit('setAccessToken', access_token);
                                context.commit('setTokenPromise', null);
                                return access_token;
                            })
                            .catch(error => error)
                        :
                        Promise.resolve(context.state.access_token)
                );
            }
            return context.state.tokenPromise;
        }
    },
    getters: {
    }
}